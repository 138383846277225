import App from './app/app';
import {
  Analytics,
  AuthenticatedSection,
  AuthProvider,
} from '@readcloud/common';
import { AppDispatch, reportActions } from '@readcloud/state';
import { Toaster, TooltipProvider } from '@readcloud/ui';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { environment } from './environments/environment';
import { store } from './redux/store';
import { WebAppVersion, ContentVersion } from '@readcloud/common/config.json';
import useIsOnline from './hooks/useIsOnline';
import { LoadingIcon } from '@readcloud/component-library';

interface IAppRootProps {}

const TAURI_ENVIRONMENT = !!(window as any).__TAURI__;

const AppRoot: React.FunctionComponent<IAppRootProps> = (props) => {
  //initialise state to the opposite of if we are in a TAURI environment.
  // true if we aren't, meaning we can forego any service worker setup
  // false if we are, meaning we need to set up service worker before we render app.
  const [serviceWorkerReady, setServiceWorkerReady] = React.useState(
    !TAURI_ENVIRONMENT
  );

  const checkServiceWorkerStatus = React.useCallback(() => {
    if ('serviceWorker' in navigator) {
      const waitForSWControl = async () => {
        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;
        console.log('Service worker is ready');

        if (navigator.serviceWorker.controller) {
          // Service worker is already controlling the page
          console.log('Service worker is already controlling the page');
          setServiceWorkerReady(true);
        } else {
          // For the initial installation, we need to wait for the service worker to activate
          console.log('Waiting for service worker to activate');
          await new Promise<void>((resolve) => {
            if (registration.active) {
              // If it's already active, resolve immediately
              resolve();
            } else {
              registration.addEventListener('activate', () => resolve(), {
                once: true,
              });
            }
          });

          // After activation, we need to reload the page to ensure the service worker controls it
          console.log('Service worker activated, reloading page');
          window.location.reload();
        }
      };

      waitForSWControl().catch((error) => {
        console.error('Error while waiting for service worker:', error);
      });
    } else {
      console.warn('Service workers are not supported in this browser');
      setServiceWorkerReady(true); // Allow the app to render even if service workers aren't supported, just means no offline support
    }
  }, []);

  React.useEffect(() => {
    if (TAURI_ENVIRONMENT) {
      checkServiceWorkerStatus();
    }
  }, [checkServiceWorkerStatus, TAURI_ENVIRONMENT]);

  // Render the app only if we're the service worker is ready
  if (serviceWorkerReady) {
    return (
      <React.Suspense
        fallback={
          <div className="rc-h-screen rc-w-full rc-flex rc-flex-col rc-items-center rc-justify-center rc-bg-gray-100">
            <LoadingIcon loading position="absolute" />
          </div>
        }
      >
        <Provider store={store}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <AuthProvider
                client_id={environment.auth.client_id}
                authority={environment.auth.authority}
                signinCallback={() => {
                  (store.dispatch as AppDispatch)(
                    reportActions.asyncActions.createUserActivity({
                      type: 'Login',

                      'App Name': 'Webapp',
                      'Webapp Version': WebAppVersion,
                      'Content Version': ContentVersion,
                    })
                  );
                }}
              >
                <TooltipProvider>
                  <Analytics apiKey={environment.posthog_id}>
                    <AuthenticatedSection>
                      <App />
                    </AuthenticatedSection>
                    <div
                      style={{ position: 'absolute' }}
                      className="readcloud-ui readcloud-ui-reset"
                    ></div>
                  </Analytics>
                </TooltipProvider>
              </AuthProvider>
            </QueryParamProvider>
            <Toaster />
          </BrowserRouter>
        </Provider>
      </React.Suspense>
    );
  } else {
    return (
      <div className="rc-h-screen rc-w-full rc-flex rc-flex-col rc-items-center rc-justify-center rc-bg-gray-100">
        <LoadingIcon loading position="absolute" />
      </div>
    );
  }
};

export default AppRoot;
