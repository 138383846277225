import { setAccessToken, v14 } from '@readcloud/api-client';
import { DecodedToken, Role, Scope, defineRules } from '@readcloud/casl';
import { AbilityContext, tokenContext } from '@readcloud/casl-react';
import { authActions } from '@readcloud/state';
import { Dialog, DialogContent, toast } from '@readcloud/ui';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthProvider as OIDCAuthProvider, useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as z from 'zod';
import { trackEvent } from './analytics';
import { recordError } from './rum';
import { decodeJwt } from '@readcloud/common';
const RedirectState = z.object({
  redirectTo: z.string(),
  attempts: z.number(),
});

export type AuthContextUser = {
  accessToken: string;
  email: string;
  firstName: string;
  lastName: string;
  institution: string;
  permissions: string[];
  reseller?: string;
  sub: string;
  id: string;
  clientId: string;
  role: string;
  scope: string;
  userScopes?: string[];
};

export type AuthContext = {
  user?: AuthContextUser;
  logout: () => void;
};
const authContext = createContext<null | AuthContext>(null);

function decodeJwtAugmented(jwtString: string) {
  try {
    const payload = decodeJwt(jwtString).payload;
    payload['accessToken'] = jwtString;
    payload['id'] = payload.sub;
    return payload as DecodedToken;
  } catch (e) {
    recordError(e);
    return null;
  }
}

/**
 * This provides our custom context for the app. Should not be used outside of this module.
 */
function AuthContextProvider(props: { children: React.ReactNode }) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const currentUser = useMemo(
    () =>
      auth.user?.access_token
        ? decodeJwtAugmented(auth.user.access_token)
        : null,
    [auth.user?.access_token]
  );

  const logout = useCallback(async () => {
    auth.signoutRedirect({ post_logout_redirect_uri: location.origin });
  }, []);

  useEffect(() => {
    if (auth.error) {
      trackEvent('AuthenticationError', {
        message: auth.error?.message || 'Unknown error',
      });
      recordError(auth.error);
    }
  }, [auth.error]);

  useEffect(() => {
    // Detect if the user needs to login, then redirect them
    if (!auth.isAuthenticated && !auth.isLoading) {
      if (auth.error) {
        logout();
      } else {
        const parsed = RedirectState.safeParse(auth.user?.state);
        auth.signinRedirect({
          state: {
            redirectTo: location.href,
            // Increase the number of attempts to prevent a loop. See below where we bail if the number of attempts is too high
            attempts: ((parsed.success && parsed?.data.attempts) || 0) + 1,
          },
        });
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, auth.error]);

  useEffect(() => {
    if (auth.user?.access_token) {
      dispatch(authActions.setAccessToken(auth.user.access_token));
      v14.addBearerToken(auth.user.access_token);
      setAccessToken(auth.user.access_token);
    }
  }, [auth.user?.access_token]);

  const ability = useMemo(() => {
    if (currentUser) {
      return defineRules(
        currentUser.role as Role,
        currentUser.userScopes as Scope[]
      );
    }

    return null;
  }, [currentUser]);

  return (
    <authContext.Provider
      value={{
        user: currentUser,
        logout,
      }}
    >
      <tokenContext.Provider value={currentUser}>
        <AbilityContext.Provider value={ability}>
          {props.children}
        </AbilityContext.Provider>
      </tokenContext.Provider>
    </authContext.Provider>
  );
}

// This is a workaround to prevent the issue where refresh tokens are shared across tabs/windows when clicking "Duplicate Tab".
// If they're shared, then theres a chance a refresh token will get used twice.
// IMPORTANT NOTE AND LESSIN: If a refresh token is attempted to be used twice, the whole grant is revoked, meaning the user is logged out from everywhere that started from the same point; the whole chain of tokens is revoked.
// https://github.com/panva/node-oidc-provider/blob/main/docs/README.md#rotaterefreshtoken
try {
  window.addEventListener('beforeunload', function (event) {
    window.sessionStorage.removeItem('__lock');
  });

  if (window.sessionStorage.getItem('__lock')) {
    window.sessionStorage.clear();
    console.warn('Found a lock in session storage. The storage was cleared.');
  }

  window.sessionStorage.setItem('__lock', '1');
} catch (e) {}

/**
 * Use this to provide OIDC authentication to an application.
 * You'll need to use it if you want to use AuthenticatedSection and useReadCloudAuth.
 *
 * Also provides "AuthORIZATION" through casl-react, which tells children about the user's permissions.
 */
export function AuthProvider(props: {
  authority: string;
  client_id: string;
  children: React.ReactNode;
  signinCallback?: () => void;
}) {
  const history = useHistory();

  // This is how we support legacy LTI or overrided logins!! (A hack)
  // We don't really need to "watch" for changes to rc5. We only need to know about this on the first page load; if users enter through a presigned (of sorts) URL.
  const rc5 = useMemo(() => {
    const queryStringMap = new URLSearchParams(location.search);
    return queryStringMap.get('rc5');
  }, []);
  return (
    <OIDCAuthProvider
      extraQueryParams={rc5 && typeof rc5 === 'string' ? { rc5 } : undefined}
      authority={props.authority}
      client_id={props.client_id}
      redirect_uri={window.location.origin}
      automaticSilentRenew
      onSigninCallback={(user) => {
        props.signinCallback && props.signinCallback();
        const state = RedirectState.safeParse(user && user.state);

        // Only allow two auth -> app -> auth redirects, just in case there is a loop.
        if (state.success && state.data.attempts < 2) {
          const url = new URL(state.data.redirectTo);
          history.replace({
            pathname: url.pathname,
            search: url.search,
            hash: url.hash,
          });
        } else {
          history.replace({
            pathname: location.pathname,
          });
        }
      }}
    >
      <AuthContextProvider>{props.children}</AuthContextProvider>
    </OIDCAuthProvider>
  );
}

/**
 * Use this to access ReadCloud's authentication context.
 * Must be used underneath an AuthProvider
 */
export function useReadCloudAuth() {
  const ctx = useContext(authContext);
  if (!ctx) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return ctx;
}

/**
 * Use this to block off a section of your app that requires authentication.
 * This will show a loading indicator while the user is being authenticated.
 * If the user is not authenticated, they will be redirected to the login page.
 */
export function AuthenticatedSection(props: { children: React.ReactNode }) {
  const auth = useAuth();
  const [errorOverride, setErrorOverride] = useState(false);

  // useEffect(() => {
  //   let dismissToast;
  //
  //   if (auth.activeNavigator?.startsWith('signin')) {
  //     dismissToast = toast({ description: 'Signing you in...' });
  //   } else if (auth.activeNavigator?.startsWith('signout')) {
  //     dismissToast = toast({ description: 'Signing you out...' }).dismiss;
  //   } else if (auth.isLoading) {
  //     dismissToast = toast({ description: 'Loading...' }).dismiss;
  //   }
  //   return () => {
  //     if (dismissToast) {
  //       dismissToast();
  //     }
  //   };
  // }, [auth.activeNavigator, auth.isLoading]);

  if (auth.isAuthenticated && auth.user) {
    return (
      <>
        {auth.error && !errorOverride && (
          <Dialog open>
            <DialogContent
              style={{
                display: 'grid',
                placeContent: 'center',
                paddingBottom: '1rem',
              }}
            >
              Your session has expired.
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  return auth.signinPopup();
                }}
              >
                Sign in again
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setErrorOverride(true);
                }}
              >
                Continue working
              </a>
            </DialogContent>
          </Dialog>
        )}
        {props.children}
      </>
    );
  } else {
    return null;
  }
}
